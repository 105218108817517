import Error404 from '../lottiefiles/404.json';
import LottieComp from './lottie';

export const ErrorAnimation = () => {
  return (
    <div className="w-full h-full">
      <LottieComp
        style={{ width: '400px', height: '400px' }}
        src={JSON.stringify(Error404)}
      />
    </div>
  );
};
