import { statsigState } from '../state/statsig';
import { useEffect } from 'react';
import { retry } from '../retry';
import { propagateError } from '../propagate-error';
import { StatsigClient } from '@statsig/js-client';

export function useInitializestatsig(statsigKey: string) {
  useEffect(() => {
    const initialize = async () => {
      try {
        const client = new StatsigClient(statsigKey, { userID: 'user-id' });
        await client.initializeAsync();

        statsigState.client = client;

        return true;
      } catch (err) {
        propagateError(err, 'failed to initialize statsig');
        return false;
      }
    };

    retry('initialize-statsig', initialize, {
      maxRetries: 3,
      shouldRetryOnSuccess: (succeeded) => !succeeded,
    }).catch((err) => {
      propagateError(err, 'failed to initialize statsig with retries');
    });
  }, [statsigKey]);
}
