import { Link } from '@remix-run/react';
import Sprite from '../../sprites/sprite';
import { useCallback } from 'react';
import { mobileNav } from '../../state/mobile-nav';
import { useSnapshot } from 'valtio';
import { pages } from '../../pages/pages-all';
import { images } from '../../constants/images';
import { useMessages } from '../../i18n/useMessages';

export const MobileNavigationDialog = () => {
  const { isOpen } = useSnapshot(mobileNav);
  const onClickClose = useCallback(() => (mobileNav.isOpen = false), []);

  const getMessage = useMessages();

  if (isOpen) {
    return (
      <div className="transition-all duration-300 animate-[fadeIn_0.5s] fixed top-0 left-0 w-full h-full bg-black/50 flex flex-col items-center justify-center z-50">
        <nav className="overflow-y-auto h-[95%] w-[95%] z-30 bg-white rounded-lg">
          <div className="w-full relative mt-7">
            <Link prefetch="intent" to="/" onClick={onClickClose}>
              <img
                loading="lazy"
                alt="The logo of camp67, a Kumbh campsite in Prayagraj, India."
                src={images['logo-dark']}
                className="m-auto w-20 pointer-events-none select-none"
              />
            </Link>
            <button
              onClick={onClickClose}
              className="absolute right-5 top-3 w-12 h-12 rounded-full border-2 border-gray-300 flex flex-col items-center justify-center"
            >
              <Sprite name="menu-close" width="17" height="18" />
            </button>
          </div>
          <ul className="flex flex-col pl-8 pb-12 mt-8 gap-8">
            {pages.map((page, index) => (
              <div key={index} className="flex flex-col gap-3">
                <li className="m-0">
                  <Link
                    prefetch="intent"
                    to={page.path}
                    onClick={onClickClose}
                    className="uppercase text-base tracking-widest font-bold"
                  >
                    {getMessage(page.title)}
                  </Link>
                </li>
                <ul className="flex flex-col gap-2">
                  {page.children.map((child, childIndex) => (
                    <li key={childIndex} className="m-0">
                      <Link prefetch="intent" to={child.path} onClick={onClickClose}>
                        {getMessage(child.title)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </ul>
        </nav>
      </div>
    );
  }
};
