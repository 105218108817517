export const keywords = [
  'Kumbh Mela Accommodation',
  'Kumbh Mela',
  'kumbh',
  'kumbh mela',
  'kumbh 2025',
  'kumbh accommodation',
  'prayag raj',
  'campsite',
  'kumbh camp',
  'kumbh sukrit',
];
