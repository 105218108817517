import clsx from 'clsx';
import { snackbarState } from './snackbar-state';
import { useState } from 'react';
import { useMessages } from '../../i18n/useMessages';

export const Snackbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  snackbarState.registerOnOpenCallback(() => {
    setIsOpen(true);
  });

  snackbarState.registerOnCloseCallback(() => {
    setIsOpen(false);
  });

  const getMessage = useMessages();

  if (!isOpen) {
    return null;
  }

  const isError = snackbarState.type === 'error';

  if (!snackbarState.message) {
    return;
  }

  return (
    <div
      className={clsx(
        'fixed flex flex-row items-start gap-2 left-1/2 -translate-x-1/2 max-w-96 top-6 px-6 py-4 rounded-md z-50 font-sans text-sm animate-[bounceTop_0.1s] hover:scale-[1.01] active:scale-[0.99] transition shadow-xl',
        {
          'bg-black text-white': isError,
        },
      )}
    >
      <p>{snackbarState.icon ? `${snackbarState.icon} ` : null}</p>
      <p className="font-medium">{getMessage(snackbarState.message)}</p>
    </div>
  );
};
