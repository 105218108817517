import * as amplitude from '@amplitude/analytics-browser';

import { AmplitudeEvent, analyticsState } from '../state/amplitude';
import { useEffect } from 'react';
import { retry } from '../retry';
import { ref } from 'valtio';
import { useLocation } from '@remix-run/react';
import { useAnalytics } from './use-analytics';
import { propagateError } from '../propagate-error';

export function useInitializeAmplitude(amplitudeKey: string) {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    const initialize = async () => {
      try {
        const initializer = amplitude.init(amplitudeKey, { defaultTracking: false });
        await initializer.promise;

        analyticsState.amplitude = ref(amplitude);
        analyticsState.amplitude.add({
          name: 'screenSource',
          execute: async (event) => ({
            ...event,
            screenSource: new URL(window.location.href).pathname,
          }),
        });
        return true;
      } catch (err) {
        propagateError(err, 'failed to initialize amplitude');
        return false;
      }
    };

    retry('initialize-amplitude', initialize, {
      maxRetries: 3,
      shouldRetryOnSuccess: (succeeded) => !succeeded,
    }).catch((err) => {
      propagateError(err, 'failed to initialize amplitude with retries');
    });
  }, [amplitudeKey]);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    analytics?.track(AmplitudeEvent.PAGE_VIEW, { screenSource: location.pathname });
  }, [analytics, location]);
}
